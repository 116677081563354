import {
  type SelectChangeEvent,
  TextField,
  type TextFieldProps,
  Typography
} from "@mui/material";
import InputOptionsFields from "../UpdateOptionsTextField";
import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import InputTypeSelect from "../builderDrawer/components/drawerContent/inputs/inputBuilders/InputTypeSelect";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../globalTheme/muiUtils/appTheme";

const TextFieldWithLabel = (
  props: TextFieldProps & { label: string; caption?: string }
) => {
  const { label, className, caption, ...rest } = props;
  return (
    <div className={className}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <TextField {...rest} />
      {caption && (
        <Typography variant="caption" color="text.secondary">
          {caption}
        </Typography>
      )}
    </div>
  );
};

const AddInput = ({
  newField,
  setNewField,
  allowedFields,
  hideTypeDropdown,
  changeInputName,
  changeInputDescription,
  changeInputType
}: {
  newField: CustomToolInputField;
  setNewField: (value: CustomToolInputField) => void;
  changeInputName: (name: string) => void;
  changeInputDescription: (description: string) => void;
  changeInputType: (
    type: CustomToolInputFieldTypes,
    field: CustomToolInputField
  ) => void;
  allowedFields?: CustomToolInputFieldTypes[];
  hideTypeDropdown?: boolean;
}) => {
  const handleInputNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeInputName(e.target.value);
  };

  const handleInputDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    changeInputDescription(e.target.value);
  };

  const handleInputTypeChange = (e: SelectChangeEvent<string>) => {
    changeInputType(e.target.value as CustomToolInputFieldTypes, newField);
  };

  return (
    <>
      {!hideTypeDropdown && (
        <InputTypeSelect
          type={newField.type}
          margin="normal"
          onChange={handleInputTypeChange}
          allowedFields={allowedFields}
          className="m-b-16px"
        />
      )}
      <TextFieldWithLabel
        fullWidth
        autoFocus
        label="Name"
        autoComplete="off"
        value={newField.name || ""}
        onChange={handleInputNameChange}
        className="m-b-16px"
        inputProps={{
          pattern: "[a-zA-Z0-9\\s_-]*"
        }}
      />

      <TextFieldWithLabel
        fullWidth
        label="Description"
        autoComplete="off"
        className="m-b-16px"
        multiline
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        value={newField.description || ""}
        onChange={handleInputDescriptionChange}
        caption="Add guidance for users"
      />
      <InputOptionsFields newField={newField} setNewField={setNewField} />
    </>
  );
};

export default AddInput;
