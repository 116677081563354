import { FIVE_MEGABYTES_IN_BYTES, TEXT_FILE_TYPES } from "@toolflow/shared";
import { isString } from "lodash";
import { useFormContext } from "react-hook-form";
import FileUploadButtonInner from "../../files/fileUpload/FileUploadButtonInner";
import { SNIPPET_VALUE_FIELD_LABEL } from "./snippetFields";

export default function SnippetFileField() {
  const { watch, setValue } = useFormContext();
  const filedValues = watch();
  const value = filedValues[SNIPPET_VALUE_FIELD_LABEL];

  if (isString(value) && value.length) {
    return null;
  }

  return (
    <>
      <FileUploadButtonInner
        showUploadedFiles
        currentFile={filedValues[SNIPPET_VALUE_FIELD_LABEL]}
        setCurrentFile={(file) => setValue(SNIPPET_VALUE_FIELD_LABEL, file)}
        helperText="txt/doc/docx, max 5 MB"
        maxSize={FIVE_MEGABYTES_IN_BYTES}
        accept={TEXT_FILE_TYPES}
      />
    </>
  );
}
