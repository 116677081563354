// For mime-type reference visit: https://mimetype.io/all-types

import { isPlainObject } from "lodash";
import { Accept } from "../../types";

export const AUDIO_FILE_TYPES: Accept = {
  "audio/mpeg": [".mp3"],
  "audio/mp4": [
    ".mp4",
    ".m4a",
    ".m4b",
    ".m4p",
    ".m4r",
    ".m4v",
    ".mp4v",
    ".3gp",
    ".3g2",
    ".3ga",
    ".3gpa",
    ".3gpp",
    ".3gpp2",
    ".3gp2"
  ],
  "audio/wav": [".wav"],
  "audio/aac": [".aac"],
  "audio/flac": [".flac"],
  "audio/ogg": [".oga", ".ogg", ".spx"],
  "audio/opus": [".opus"],
  "audio/webm": [".weba"]
};

export const VIDEO_FILE_TYPES: Accept = {
  "video/mp4": [".mp4"],
  "video/webm": [".webm"],
  "video/mpeg": [".mpeg", ".mpg"]
};

export const TEXT_FILE_TYPES: Accept = {
  "text/plain": [".txt"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ]
};

export const ONE_MEGABYTE_IN_BYTES = 1048576;
export const FIVE_MEGABYTES_IN_BYTES = ONE_MEGABYTE_IN_BYTES * 5;
export const FIVE_HUNDRED_MEGABYTES_IN_BYTES = ONE_MEGABYTE_IN_BYTES * 500;

export function getFileExtensions(types: Accept) {
  if (!types || !isPlainObject(types)) {
    return [];
  }
  return Object.values(types).flat();
}
