import { useEffect } from "react";
import useCurrentAssetsRef from "../../../../pages/workstation/assets/useCurrentAssetsRef";
import useToolCompleted from "./useToolCompleted";
import useSetInputLoadingFalse from "./useSetInputLoadingFalse";
import useToolStillLoading from "./useToolStillLoading";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import useGetCurrentChatThreadId from "../../../../pages/workstation/components/chat/chatbox/hooks/useGetCurrentChatThreadId";
import { useAddToolOutputToChatMutation } from "../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import useSetThreadId from "../../../../pages/workstation/components/chat/chatbox/hooks/useSetThreadId";
import type { Asset } from "@toolflow/shared";
import useGetCurrentChatAgentIdOrDefault from "../../../../pages/workstation/components/chat/chatbox/hooks/useGetCurrentAgentIdOrDefault";

const useAddLoadingAssetsToCache = () => {
  const threadId = useGetCurrentChatThreadId();
  const [updateTVR] = useAddToolOutputToChatMutation();
  const setThreadId = useSetThreadId();
  const { agentId } = useGetCurrentChatAgentIdOrDefault();

  const handleAddLoadingAssetsToCache = async (assets: Asset[]) => {
    await updateTVR({
      threadId,
      assets,
      agentId
    })
      .unwrap()
      .then((resp) => {
        if (threadId !== resp.result.dbThreadId) {
          setThreadId(resp.result.dbThreadId);
        }
      });
  };
  return handleAddLoadingAssetsToCache;
};

const useCleanupToolOnComplete = () => {
  const { handleClose } = useEditorToolCardContext();
  const currentAssets = useCurrentAssetsRef();

  const toolCompleted = useToolCompleted();

  const setInputLoadingFalse = useSetInputLoadingFalse();
  const toolStillLoading = useToolStillLoading();

  const handleAddLoadingAssetsToCache = useAddLoadingAssetsToCache();

  // i think we need a timer here instead of a queue microtask,
  // because otherwise, the end of the prompt might get cut off
  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (toolCompleted) {
      timerId = setTimeout(() => {
        handleAddLoadingAssetsToCache(
          currentAssets.current.filter((asset) => asset.loading)
        );
        setInputLoadingFalse(currentAssets.current);
        handleClose();
      }, 300);

      // note -> previously we opened / closed websockets here
      // deleting a node closed the websocket which messed up any other
      // websocket call
      // instead, we are keeping one websocket open for the entire app
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [toolStillLoading]);
};

export default useCleanupToolOnComplete;
