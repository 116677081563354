import { useEffect, useMemo } from "react";
import { useFormContext, type FieldValues } from "react-hook-form";
import type { BlockerFunction } from "react-router-dom";
import { BYPASS_BLOCKER_QUERY_PARAM } from "../../features/navigation/helpers/routePaths";

// isDirty gets weird, better to find length of fields
// https://stackoverflow.com/questions/64736717/react-hook-form-isdirty-seems-weird-for-me
function useFormIsDirty<T extends FieldValues>() {
  const { formState } = useFormContext<T>();

  // we only want dirty fields that return "true"
  const getTrueDirtyFields = (
    fields: Record<string, $TSAllowedAny>
  ): Record<string, $TSAllowedAny> => {
    const result: Record<string, $TSAllowedAny> = {};

    for (const key in fields) {
      if (fields[key] === true) {
        result[key] = true;
      } else if (typeof fields[key] === "object" && fields[key] !== null) {
        const nestedResult = getTrueDirtyFields(fields[key]);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      }
    }

    return result;
  };

  const trueDirtyFields = useMemo(() => {
    return getTrueDirtyFields(formState.dirtyFields);
  }, [formState.dirtyFields, formState.isDirty]); // the formState.isDirty is necessary for useSetThreadId

  useEffect(() => {}, [JSON.stringify(trueDirtyFields)]); // we want to trigger rerenders when trueDirtyFields change. For some reason, without this it doesnt rerender enough

  return {
    isDirty: Object.keys(trueDirtyFields).length > 0,
    isDirtyFunc: ({ nextLocation }: BlockerFunction["arguments"]) => {
      console.log("trueDirtyFields", trueDirtyFields);
      return (
        Object.keys(trueDirtyFields).length > 0 &&
        nextLocation.search !== BYPASS_BLOCKER_QUERY_PARAM
      );
    },
    dirtyFields: trueDirtyFields,
    formStateIsDirty: formState.isDirty
  };
}

export default useFormIsDirty;
