import {
  Block,
  BlockNode,
  BlockType,
  DEFAULT_VERSION_VALUE,
  OpenAiTextModel,
  UtilBlockType,
  VERSION_2_VALUE,
  type AllBlockTypes,
  type DallEBlockDataV2,
  type DeepgramBlockDataV1,
  type DeepgramBlockDataV2,
  type IterationExitBlockData,
  type IterationStartBlockData,
  type KnowledgeBlockData,
  type PerplexityBlockDataV1,
  type PerplexityBlockDataV2,
  type PromptBlockDataV1,
  type PromptBlockDataV2,
  type SavetoKnowledgeBlockData,
  type ScraperBlockDataV1,
  type ScraperBlockDataV2,
  type SerpBlockDataV1,
  type SerpBlockDataV2,
  type StructuredBlockDataV1,
  type StructuredBlockDataV2,
  type VersionedBlockConfig,
  type YoutubeTranscriptBlockData
} from "@toolflow/shared";
import { cloneDeep } from "lodash";
import {
  ConstantIcon,
  CrawlerIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  IterationEndIcon,
  IterationStartIcon,
  KnowledgeIcon,
  LogicIcon,
  SaveToKnowledgeFillIcon,
  SpeechToTextFillIcon,
  ToolIcon,
  YoutubeTranscriptIcon
} from "../../../../../../globalTheme/icons/icons";
import GoogleLogo from "../../../../../../globalTheme/icons/logos/GoogleLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";
import PerplexityLogo from "../../../../../../globalTheme/icons/logos/PerplexityLogo";
import {
  dallEInitialStateV2,
  dallEPromptInitialState,
  deepgramTranscribePromptInitialStateV1,
  deepgramTranscribePromptInitialStateV2,
  iterationExitSettingsInitialState,
  iterationStartSettingsInitialState,
  promptInitialState,
  scraperSettingsInitialStateV1,
  scraperSettingsInitialStateV2,
  serpSettingsInitialStateV1,
  serpSettingsInitialStateV2
} from "../../context/initialStates";
import { knowledgeInitialState } from "../../context/initialStates/knowledgeInitialState";
import {
  perplexityInitialStateV1,
  perplexityInitialStateV2
} from "../../context/initialStates/perplexityInitialState";
import { saveToKnowledgeInitialState } from "../../context/initialStates/saveToKnowledgeInitialState";
import { structuredInitialState } from "../../context/initialStates/structuredInitialState";
import { youtubeTranscriptInitialState } from "../../context/initialStates/youtubeTranscriptInitialState";
import ConstantBlockInnerV1 from "../constantBlock/versions/1.0/ConstantBlockInnerV1";
import ConstantBlockInnerV2 from "../constantBlock/versions/2.0/ConstantBlockInnerV2";
import ConstantTestFields from "../constantBlock/versions/2.0/ConstantTestFields";
import DallEBlockInner from "../dalleBlock/DallEBlockInner";
import TestDallE from "../dalleBlock/TestDallE";
import DeepgramBlockInnerV1 from "../deepgram/versions/v1/DeepgramBlockInnerV1";
import TestDeepgramV1 from "../deepgram/versions/v1/TestDeepgramV1";
import DeepgramBlockInnerV2 from "../deepgram/versions/v2/DeepgramBlockInnerV2";
import TestDeepgramV2 from "../deepgram/versions/v2/TestDeepgramV2";
import IterationExitBlockInner from "../IterationExitBlock/IterationExitBlockInner";
import IterationStartBlockInner from "../IterationStartBlock/IterationStartBlockInner";
import KnowledgeBlockInner from "../knowledgeBlock/KnowledgeBlockInner";
import TestKnowledge from "../knowledgeBlock/TestKnowledge";
import LogicInner from "../logic/LogicInner";
import PerplexityBlockInnerV1 from "../perplexityBlock/versions/1.0/PerplexityBlockInnerV1";
import TestPerplexityV1 from "../perplexityBlock/versions/1.0/TestPerplexityV1";
import PerplexityBlockInnerV2 from "../perplexityBlock/versions/2.0/PerplexityBlockInnerV2";
import TestPerplexityV2 from "../perplexityBlock/versions/2.0/TestPerplexityV2";
import PromptBlockInner from "../promptBlocks/versions/1.0/PromptBlockInner";
import TestPrompt from "../promptBlocks/versions/1.0/TestPrompt";
import PromptBlockInnerv2 from "../promptBlocks/versions/2.0/PromptBlockInnerv2";
import TestPromptV2 from "../promptBlocks/versions/2.0/TestPromptV2";
import SavetoKnowledgeBlockInner from "../savetoKnowledge/SavetoKnowledgeBlockInner";
import ScraperBlockInnerV1 from "../scraperBlock/versions/v1/ScraperBlockInnerV1";
import TestScraperV1 from "../scraperBlock/versions/v1/TestScraperV1";
import ScraperBlockInnerV2 from "../scraperBlock/versions/v2/ScraperBlockInnerV2";
import TestScraperV2 from "../scraperBlock/versions/v2/TestScraperV2";
import SerpBlockInnerV1 from "../serpBlock/versions/1.0/SerpBlockInnerV1";
import TestSerpV1 from "../serpBlock/versions/1.0/TestSerpV1";
import SerpBlockInnerV2 from "../serpBlock/versions/2.0/SerpBlockInnerV2";
import TestSerpV2 from "../serpBlock/versions/2.0/TestSerpV2";
import StructuredBlockInnerV1 from "../structuredBlock/versions/1.0/StructuredBlockInnerV1";
import TestStructuredV1 from "../structuredBlock/versions/1.0/TestStructuredV1";
import StructuredBlockInnerV2 from "../structuredBlock/versions/2.0/StructuredBlockInnerV2";
import TestStructuredV2 from "../structuredBlock/versions/2.0/TestStructuredV2";
import ToolWithinToolBlockInner from "../toolWithinTool/ToolWithinToolBlockInner";
import TestYoutubeTranscript from "../youtubeTranscriptBlock/TestYoutubeTranscript";
import YoutubeTranscriptBlockInner from "../youtubeTranscriptBlock/YoutubeTranscriptBlockInner";
import getBlockConfigFunctions from "./blockConfigFns/blockConfigFns";
import { getBlockInputUpdaterFunction } from "./blockConfigFns/fns/blockInputUpdater";
import { getBlockValidatorByVersion } from "./blockConfigFns/fns/blockValidators";
import { getOutputConfigOptionsFn } from "./blockConfigFns/fns/outputConfigOptions";
import getBlockConfigVersion from "./helpers/getBlockConfigVersion";
import { getLatestBlockVersion } from "./helpers/getLatestBlockVersion";

export const blockConfig: Record<Block, VersionedBlockConfig> = {
  [Block.logic]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: LogicIcon,
    drawerComponent: LogicInner,
    blockPaperLabel: "Logic",
    draggableItem: {
      type: BlockNode.LogicBlockNode,
      data: {
        label: "Logic",
        type: UtilBlockType.Logic,
        logicArray: []
      }
    },
    ...getBlockConfigFunctions(UtilBlockType.Logic)
  }),
  [Block.constant]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: ConstantIcon,
      drawerComponent: ConstantBlockInnerV1,
      blockPaperLabel: "Text composer",
      draggableItem: {
        type: BlockNode.ConstantBlockNode,
        data: {
          label: "Text",
          type: UtilBlockType.Constant,
          constant: ""
        }
      },
      ...getBlockConfigFunctions(UtilBlockType.Constant)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: ConstantIcon,
      drawerComponent: ConstantBlockInnerV2,
      expandedComponent: ConstantTestFields,
      blockPaperLabel: "Text composer",
      validate: getBlockValidatorByVersion(UtilBlockType.Constant),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        UtilBlockType.Constant,
        VERSION_2_VALUE
      ),
      draggableItem: {
        type: BlockNode.ConstantBlockNode,
        data: {
          label: "Text",
          type: UtilBlockType.Constant,
          constant: ""
        }
      }
    })
  },
  [Block.textGeneration]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      drawerComponent: PromptBlockInner,
      expandedComponent: TestPrompt,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          ...promptInitialState
        } as PromptBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.ChatGPT)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      drawerComponent: PromptBlockInnerv2,
      expandedComponent: TestPromptV2,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          settings: {
            prompt: "",
            llmModel: OpenAiTextModel.GPT4o,
            temperature: 50
          }
        } as PromptBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.ChatGPT, VERSION_2_VALUE)
    })
  },
  [Block.imageGeneration]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      drawerComponent: PromptBlockInner,
      expandedComponent: TestPrompt,
      blockPaperLabel: "Image generator",
      icon: ImageGenerationIcon,
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.DallE,
          label: "Image Output Name",
          ...dallEPromptInitialState
        } as PromptBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.DallE)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      drawerComponent: DallEBlockInner,
      expandedComponent: TestDallE,
      blockPaperLabel: "Image generator",
      icon: ImageGenerationIcon,
      draggableItem: {
        type: BlockNode.DallEBlockNode,
        data: {
          type: BlockType.DallE,
          label: "Image Output Name",
          ...dallEInitialStateV2
        } as DallEBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.DallE, VERSION_2_VALUE)
    })
  },
  [Block.speechToText]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: SpeechToTextFillIcon,
      drawerComponent: DeepgramBlockInnerV1,
      expandedComponent: TestDeepgramV1,
      blockPaperLabel: "Speech to text",
      draggableItem: {
        type: BlockNode.DeepgramBlockNode,
        data: {
          ...deepgramTranscribePromptInitialStateV1,
          type: BlockType.Deepgram,
          label: "Transcript"
        } as DeepgramBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.Deepgram)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: SpeechToTextFillIcon,
      drawerComponent: DeepgramBlockInnerV2,
      expandedComponent: TestDeepgramV2,
      blockPaperLabel: "Speech to text",
      draggableItem: {
        type: BlockNode.DeepgramBlockNode,
        data: {
          ...deepgramTranscribePromptInitialStateV2,
          type: BlockType.Deepgram,
          label: "Transcript"
        } as DeepgramBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.Deepgram, VERSION_2_VALUE)
    })
  },
  [Block.scraper]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: CrawlerIcon,
      expandedComponent: TestScraperV1,
      drawerComponent: ScraperBlockInnerV1,
      blockPaperLabel: "Web scraper",
      draggableItem: {
        type: BlockNode.ScraperBlockNode,
        data: {
          ...scraperSettingsInitialStateV1,
          type: BlockType.Scraper,
          label: "Website content"
        } as ScraperBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.Scraper)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: CrawlerIcon,
      expandedComponent: TestScraperV2,
      drawerComponent: ScraperBlockInnerV2,
      blockPaperLabel: "Web scraper",
      draggableItem: {
        type: BlockNode.ScraperBlockNode,
        data: {
          ...scraperSettingsInitialStateV2,
          type: BlockType.Scraper,
          label: "Website content"
        } as ScraperBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.Scraper, VERSION_2_VALUE)
    })
  },
  [Block.serp]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: GoogleLogo,
      drawerComponent: SerpBlockInnerV1,
      expandedComponent: TestSerpV1,
      blockPaperLabel: "Google search",
      draggableItem: {
        type: BlockNode.SerpBlockNode,
        data: {
          ...serpSettingsInitialStateV1,
          type: BlockType.SERP,
          label: "Google Search Results"
        } as SerpBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.SERP)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: GoogleLogo,
      drawerComponent: SerpBlockInnerV2,
      expandedComponent: TestSerpV2,
      blockPaperLabel: "Google search",
      draggableItem: {
        type: BlockNode.SerpBlockNode,
        data: {
          ...serpSettingsInitialStateV2,
          type: BlockType.SERP,
          label: "Google Search Results"
        } as SerpBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.SERP, VERSION_2_VALUE)
    })
  },
  [Block.structured]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: DataExtractorIcon,
      expandedComponent: TestStructuredV1,
      drawerComponent: StructuredBlockInnerV1,
      blockPaperLabel: "Data extractor",
      draggableItem: {
        type: BlockNode.StructuredBlockNode,
        data: {
          ...cloneDeep(structuredInitialState),
          type: BlockType.Structured,
          label: "Data extractor"
        } as StructuredBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.Structured)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: DataExtractorIcon,
      blockPaperLabel: "Data extractor",
      expandedComponent: TestStructuredV2,
      drawerComponent: StructuredBlockInnerV2,
      draggableItem: {
        type: BlockNode.StructuredBlockNode,
        data: {
          ...cloneDeep(structuredInitialState),
          type: BlockType.Structured,
          label: "Data extractor"
        } as StructuredBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.Structured, VERSION_2_VALUE)
    })
  },
  [Block.perplexity]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: PerplexityLogo,
      drawerComponent: PerplexityBlockInnerV1,
      expandedComponent: TestPerplexityV1,
      blockPaperLabel: "Perplexity",
      draggableItem: {
        type: BlockNode.PerplexityBlockNode,
        data: {
          ...perplexityInitialStateV1,
          type: BlockType.Perplexity,
          label: "Perplexity research"
        } as PerplexityBlockDataV1
      },
      ...getBlockConfigFunctions(BlockType.Perplexity)
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: PerplexityLogo,
      drawerComponent: PerplexityBlockInnerV2,
      expandedComponent: TestPerplexityV2,
      blockPaperLabel: "Perplexity",
      draggableItem: {
        type: BlockNode.PerplexityBlockNode,
        data: {
          ...perplexityInitialStateV2,
          type: BlockType.Perplexity,
          label: "Perplexity research"
        } as PerplexityBlockDataV2
      },
      ...getBlockConfigFunctions(BlockType.Perplexity, VERSION_2_VALUE)
    })
  },
  [Block.embedded]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: ToolIcon,
    drawerComponent: ToolWithinToolBlockInner,
    blockPaperLabel: "Embedded Tool",
    draggableItem: {
      type: BlockNode.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: UtilBlockType.ToolWithinTool,
        tool: null,
        inputMap: null
      }
    },
    ...getBlockConfigFunctions(UtilBlockType.ToolWithinTool)
  }),
  [Block.iterationStart]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationStartIcon,
    drawerComponent: IterationStartBlockInner,
    blockPaperLabel: "Start iteration",
    draggableItem: {
      type: BlockNode.IterationStartBlockNode,
      data: {
        ...iterationStartSettingsInitialState,
        type: BlockType.IterationStart,
        label: "Start iteration"
      } as IterationStartBlockData
    },
    ...getBlockConfigFunctions(BlockType.IterationStart)
  }),
  [Block.iterationExit]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationEndIcon,
    drawerComponent: IterationExitBlockInner,
    blockPaperLabel: "Exit iteration",
    draggableItem: {
      type: BlockNode.IterationExitBlockNode,
      data: {
        ...iterationExitSettingsInitialState,
        type: BlockType.IterationExit,
        label: "Exit iteration"
      } as IterationExitBlockData
    },
    ...getBlockConfigFunctions(BlockType.IterationExit)
  }),
  [Block.knowledge]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: KnowledgeIcon,
    drawerComponent: KnowledgeBlockInner,
    expandedComponent: TestKnowledge,
    blockPaperLabel: "Retrieve knowledge",
    draggableItem: {
      type: BlockNode.KnowledgeNode,
      data: {
        ...knowledgeInitialState,
        type: BlockType.Knowledge,
        label: "Retrieve knowledge"
      } as KnowledgeBlockData
    },
    ...getBlockConfigFunctions(BlockType.Knowledge)
  }),
  [Block.savetoKnowledge]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: SaveToKnowledgeFillIcon,
    drawerComponent: SavetoKnowledgeBlockInner,
    blockPaperLabel: "Save to knowledge",
    draggableItem: {
      type: BlockNode.SavetoKnowledgeNode,
      data: {
        ...saveToKnowledgeInitialState,
        type: BlockType.SavetoKnowledge,
        label: "Save to knowledge"
      } as SavetoKnowledgeBlockData
    },
    ...getBlockConfigFunctions(BlockType.SavetoKnowledge)
  }),
  [Block.youtubeTranscript]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: YoutubeTranscriptIcon,
    drawerComponent: YoutubeTranscriptBlockInner,
    expandedComponent: TestYoutubeTranscript,
    blockPaperLabel: "YouTube transcript",
    draggableItem: {
      type: BlockNode.YoutubeTranscriptNode,
      data: {
        ...youtubeTranscriptInitialState,
        type: BlockType.YoutubeTranscript,
        label: "YouTube transcript"
      } as YoutubeTranscriptBlockData
    },
    ...getBlockConfigFunctions(BlockType.YoutubeTranscript)
  })
};

export function getBlockConfig(
  block: Block,
  version = DEFAULT_VERSION_VALUE
): (typeof blockConfig)[Block][string] {
  const blockVersions = blockConfig[block];

  return blockVersions[version] || blockVersions[DEFAULT_VERSION_VALUE];
}

export function getLatestBlockConfig(type: Block) {
  return getBlockConfig(type, getLatestBlockVersion(type));
}

function getBlockByNodeType(block: AllBlockTypes) {
  const BlockMap: Record<AllBlockTypes, Block> = {
    [BlockType.ChatGPT]: Block.textGeneration,
    [BlockType.Deepgram]: Block.speechToText,
    [BlockType.Scraper]: Block.scraper,
    [BlockType.SERP]: Block.serp,
    [BlockType.Structured]: Block.structured,
    [BlockType.Perplexity]: Block.perplexity,
    [BlockType.DallE]: Block.imageGeneration,
    [BlockType.IterationStart]: Block.iterationStart,
    [BlockType.IterationExit]: Block.iterationExit,
    [BlockType.Anthropic]: Block.textGeneration,
    [BlockType.Knowledge]: Block.knowledge,
    [BlockType.SavetoKnowledge]: Block.savetoKnowledge,
    [BlockType.YoutubeTranscript]: Block.youtubeTranscript,
    [UtilBlockType.ToolWithinTool]: Block.embedded,
    [UtilBlockType.Logic]: Block.logic,
    [UtilBlockType.Constant]: Block.constant
  };

  return BlockMap[block];
}

export function getLatestBlockConfigByNodeType(blockType: AllBlockTypes) {
  const type = getBlockByNodeType(blockType);
  if (!type) {
    return null;
  }
  return getLatestBlockConfig(type);
}
