import {
  AUDIO_FILE_TYPES,
  FIVE_HUNDRED_MEGABYTES_IN_BYTES,
  VIDEO_FILE_TYPES,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import { LabelAndInfo } from "../../../utilities/components/typographies/LabelAndInfo";
import FileUploadButtonInner from "./FileUploadButtonInner";
import useCurrentFileFromFormState from "./hooks/useCurrentFileFromFormState";

interface FileUploadButtonProps {
  setFormState: TSetFormState;
  useIds?: boolean;
  id?: string;
  description?: string;
  name: string;
  formState: UserInputDictType;
  disabled?: boolean;
}

const FileUploadButton = ({
  useIds,
  id = "",
  name,
  formState,
  setFormState,
  description,
  disabled
}: FileUploadButtonProps) => {
  const { currentFile, setCurrentFile } = useCurrentFileFromFormState({
    formState,
    setFormState,
    useIds,
    id,
    name
  });

  return (
    <>
      {name && <LabelAndInfo name={name} info={description} />}
      <FileUploadButtonInner
        showUploadedFiles
        currentFile={currentFile}
        setCurrentFile={setCurrentFile}
        disabled={disabled}
        helperText="MP3/WAV/M4A/AAC, max 500 MB/2 hours"
        accept={{ ...AUDIO_FILE_TYPES, ...VIDEO_FILE_TYPES }}
        maxSize={FIVE_HUNDRED_MEGABYTES_IN_BYTES}
      />
    </>
  );
};

export default FileUploadButton;
