import { ErrorCode, type FileRejection } from "react-dropzone";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../stores/actions";
import { ONE_MEGABYTE_IN_BYTES } from "@toolflow/shared";

export function useDispatchFileRejectionError() {
  const dispatch = useDispatch();

  function dispatchFileRejectionError(fileRejections: FileRejection[]) {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((error) => {
        if (error.code === ErrorCode.FileInvalidType) {
          dispatch(
            setErrorMessage(
              `Un-supported file type for "${fileRejection.file.name}".`
            )
          );
        } else if (error.code === ErrorCode.FileTooLarge) {
          const maxSizeInBytes = parseInt(
            error.message.match(/\d/g)?.join("") || "",
            10
          );

          const maxSizeInMegaBytes = maxSizeInBytes / ONE_MEGABYTE_IN_BYTES;

          dispatch(
            setErrorMessage(`File size cannot exceed ${maxSizeInMegaBytes}MB.`)
          );
        }
      });
    });
  }

  return dispatchFileRejectionError;
}
