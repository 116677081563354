import {
  isHandledChatStackError,
  type PopulatedToolVersionResponseFE,
  type UserInputDictType
} from "@toolflow/shared";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { billingApi } from "../../../../../ToolflowAPI/rtkRoutes/billingApi";
import { setErrorMessage } from "../../../../../stores/actions";
import { globalEventEmitterFE } from "../../../../../utilities/eventEmitter/globalEventEmitterFE";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import useSetResponse from "./useSetResponse";
import useToolStillLoading from "./useToolStillLoading";
import useUpdatePercentCompleted from "./useUpdatePercentCompleted";

const useToolStillLoadingRef = () => {
  const toolStillLoading = useToolStillLoading();
  const toolStillLoadingRef = useRef(toolStillLoading);

  useEffect(() => {
    toolStillLoadingRef.current = toolStillLoading;
  }, [toolStillLoading]);
  return toolStillLoadingRef;
};

const useHandleWebsocketMessageByComponentId = (clear: () => void) => {
  const setResponse = useSetResponse();
  const { setToolOutputState, componentId } = useEditorToolCardContext();
  const dispatch = useDispatch();

  const updateToolOutput = (uI: UserInputDictType | null) => {
    queueMicrotask(() => {
      setToolOutputState((prevToolOutput) => {
        return { ...prevToolOutput, ...uI };
      });
    });
  };

  const parseWebsocketResponse = (
    toolVersionResponse: PopulatedToolVersionResponseFE
  ) => {
    setResponse(toolVersionResponse);
  };

  const updatePercentCompleted = useUpdatePercentCompleted();
  const reduxDispatch = useDispatch();
  const toolStillLoadingRef = useToolStillLoadingRef();

  useEffect(() => {
    const handleWsMessage = (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.componentId === componentId) {
        if (emittedMessage.type === "websocketToolVersionResponse") {
          parseWebsocketResponse(emittedMessage.toolVersionResponse);
          const isClosed = !!emittedMessage.close;
          if (isClosed) {
            updatePercentCompleted(100);

            if (emittedMessage.credits) {
              dispatch(
                billingApi.util.upsertQueryData("getCredits", undefined, {
                  credits: emittedMessage.credits
                }) as $TSFixMe
              );
            }
            // File is ready, we can stop checking
            clear();
          }
        }
      }
      if (emittedMessage.type === "updateToolOutput") {
        // the backend sends an "Aborting" Chatstack error
        // so we find that and then set tool output state as null
        const isAborting =
          emittedMessage.response &&
          Object.values(emittedMessage.response).reduce((acc, value) => {
            return (
              acc ||
              (isHandledChatStackError(value) &&
                value.data &&
                value.data.message === "Aborting")
            );
          }, false);

        if (isAborting) {
          setToolOutputState(null);
          clear();
          // we need to make sure that tool still loading, otherwise
          // we may receive events from the backend after abort
        } else if (toolStillLoadingRef.current) {
          updateToolOutput(emittedMessage.response);
        }
      }

      if (emittedMessage.type === "error") {
        reduxDispatch(setErrorMessage(emittedMessage.data));
        clear();
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [componentId]);
};

export default useHandleWebsocketMessageByComponentId;
