import {
  AUDIO_FILE_TYPES,
  TEXT_FILE_TYPES,
  VIDEO_FILE_TYPES
} from "@toolflow/shared";
import { ReactNode } from "react";
import FullScreenFileDropzoneProvider from "../../../../../../files/fileUpload/FullScreenFileDropzone";
import { ChatTextProvider } from "./hooks/useChatText";

export default function ChatProviders({ children }: { children: ReactNode }) {
  return (
    <FullScreenFileDropzoneProvider
      dropLabel="Drop a file to add it to conversation."
      acceptedExtensions={{
        ...AUDIO_FILE_TYPES,
        ...VIDEO_FILE_TYPES,
        ...TEXT_FILE_TYPES
      }}
    >
      <ChatTextProvider>{children}</ChatTextProvider>
    </FullScreenFileDropzoneProvider>
  );
}
