import { generateText, type JSONContent } from "@tiptap/core";
import {
  BlockType,
  DEFAULT_VERSION_VALUE,
  UtilBlockType,
  VERSION_2_VALUE,
  type AllBlockTypes,
  type ConstantBlockData,
  type DecimalString,
  type DeepgramBlockDataV1,
  type IValidateProps,
  type LogicBlockData,
  type LogicItem,
  type ScraperBlockDataV1,
  type ScraperBlockDataV2,
  type StructuredBlockDataV1,
  type StructuredBlockDataV2,
  type ToolWithinToolBlockData,
  type ValidatorFunction
} from "@toolflow/shared";
import { customExtensions } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { getExtensionsFromConfig } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import { convertToBlockMessageType } from "../../convertToBlockMessageType";

export const blockValidators: Record<
  string,
  Record<DecimalString, ValidatorFunction<$TSFixMe>>
> = {
  [BlockType.Deepgram]: {
    [DEFAULT_VERSION_VALUE]: ({
      data
    }: IValidateProps<DeepgramBlockDataV1>) => {
      if (data.settings.file === "") {
        return convertToBlockMessageType(
          `No mapped file to transcribe.`,
          "error"
        );
      }
    }
  },
  [BlockType.Scraper]: {
    [DEFAULT_VERSION_VALUE]: ({ data }: IValidateProps<ScraperBlockDataV1>) => {
      if (data.settings.urlFieldInputKey === "") {
        return convertToBlockMessageType(`No mapped url to scrape`, "error");
      }
    },
    [VERSION_2_VALUE]: ({ data }: IValidateProps<ScraperBlockDataV2>) => {
      if (data.settings.url === "") {
        return convertToBlockMessageType(`No mapped url to scrape`, "error");
      }
    }
  },
  [BlockType.Structured]: {
    [DEFAULT_VERSION_VALUE]: ({
      data
    }: IValidateProps<StructuredBlockDataV1>) => {
      if (data.settings.input === "") {
        return convertToBlockMessageType(
          `No source text added. Please enter source text for data extractor`,
          "error"
        );
      }
    },
    [VERSION_2_VALUE]: ({ data }: IValidateProps<StructuredBlockDataV2>) => {
      const extensions = getExtensionsFromConfig(
        textInputContainerExtensionsConfig,
        customExtensions
      );
      const stringifiedValue =
        generateText(data.settings.input as JSONContent, extensions) || "";

      if (stringifiedValue.trim() === "") {
        return convertToBlockMessageType(
          `No source text added. Please enter source text for data extractor`,
          "error"
        );
      }
    }
  },
  [UtilBlockType.ToolWithinTool]: {
    [DEFAULT_VERSION_VALUE]: ({
      data
    }: IValidateProps<ToolWithinToolBlockData>) => {
      if (
        data.inputMap &&
        Object.values(data.inputMap).some((value) => value === "")
      ) {
        return convertToBlockMessageType(
          `Not all inputs are mapped.`,
          "warning"
        );
      }
    }
  },
  [UtilBlockType.Constant]: {
    [DEFAULT_VERSION_VALUE]: ({ data }: IValidateProps<ConstantBlockData>) => {
      if (!data.constant) {
        return convertToBlockMessageType(
          `A constant must have a value.`,
          "error"
        );
      }
    }
  },
  [UtilBlockType.Logic]: {
    [DEFAULT_VERSION_VALUE]: ({
      data,
      edges,
      id
    }: IValidateProps<LogicBlockData>) => {
      if (
        data?.logicArray.filter((item: LogicItem) => item.input !== "")
          .length === 0
      ) {
        return convertToBlockMessageType(
          `Logic datas can't be empty. Add a filter.`,
          "error"
        );
      }
      if (!edges.some((e) => e.source === id)) {
        return convertToBlockMessageType(
          `Logic datas must connect their output to another data. Delete this data if it is unused.`,
          "error"
        );
      }
      if (
        data?.logicArray.some((logic: LogicItem) => logic.parameterValue === "")
      ) {
        return convertToBlockMessageType(
          `Logic filters need to be fully filled out.`,
          "error"
        );
      }
    }
  }
};

/**
 *
 * @param type
 * @param version @default 1.0
 * @returns ValidatorFunction
 */
export function getBlockValidatorByVersion(
  type: AllBlockTypes,
  version: DecimalString = DEFAULT_VERSION_VALUE
) {
  if (blockValidators.hasOwnProperty(type)) {
    return (
      blockValidators[type][version] ||
      blockValidators[type][DEFAULT_VERSION_VALUE]
    );
  }
}
