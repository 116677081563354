import { Typography } from "@mui/material";
import {
  FIVE_MEGABYTES_IN_BYTES,
  ONE_MEGABYTE_IN_BYTES,
  type SelectKnowledgeFieldConfig
} from "@toolflow/shared";
import { type DropzoneOptions, useDropzone } from "react-dropzone";
import { UploadIcon } from "../../../../../../../../../globalTheme/icons/icons";
import CircleIcon from "../../../../../../../../../utilities/components/icons/CircleButton";
import { useDispatchFileRejectionError } from "../../../../../../../../files/fileUpload/hooks/useDispatchFileRejectionError";
import styles from "../selectKnowledgeField.module.css";

export default function FileFieldDropZone({
  config,
  onDrop
}: {
  config: SelectKnowledgeFieldConfig;
  onDrop: DropzoneOptions["onDrop"];
}) {
  const { acceptedExtensions, maxSize = FIVE_MEGABYTES_IN_BYTES } = config;

  const onDropRejected = useDispatchFileRejectionError();

  const maxSizeText = `${maxSize / ONE_MEGABYTE_IN_BYTES}MB`;
  const acceptedExtensionsText = Object.values(acceptedExtensions || {}).reduce(
    (acc, key) => {
      acc = [acc, ...key.map((ext) => ext.replace(".", ""))]
        .filter((ext) => !!ext)
        .join(",");
      return acc;
    },
    ""
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxSize,
    noClick: true,
    noKeyboard: true,
    accept: acceptedExtensions,
    onDropRejected
  });

  return (
    <>
      <Typography variant="h8">Upload a file</Typography>
      <div
        {...getRootProps()}
        className={styles["dropzone-style"]}
        onClick={open}
      >
        <input {...getInputProps()} />
        <CircleIcon size={20} Icon={UploadIcon} />
        <Typography align="center" variant="h8" className="m-t-8px">
          Click or drop to upload your files here
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {acceptedExtensionsText ? `${acceptedExtensionsText} / ` : ""}max{" "}
          {maxSizeText}
        </Typography>
      </div>
    </>
  );
}
