import { Typography } from "@mui/material";
import { useDropzone, type Accept } from "react-dropzone";
import { UploadIcon } from "../../../globalTheme/icons/icons";
import CircleIcon from "../../../utilities/components/icons/CircleButton";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import styles from "./fileDropzone.module.css";
import { useDispatchFileRejectionError } from "./hooks/useDispatchFileRejectionError";

const cx = parsedClassNames.bind(styles);

const FileDropzone = ({
  acceptedExtensions,
  disabled,
  helperText = "",
  className,
  maxSize,
  handleFileSelect
}: {
  disabled?: boolean;
  acceptedExtensions?: Accept;
  helperText?: string;
  className?: string;
  maxSize?: number;
  handleFileSelect: (files: File[]) => void;
}) => {
  const onDropRejected = useDispatchFileRejectionError();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileSelect,
    accept: acceptedExtensions,
    disabled,
    maxSize,
    onDropRejected
  });
  return (
    <div
      {...getRootProps()}
      className={cx(
        styles["dropzone-style"],
        { "cursor-pointer": !disabled },
        className
      )}
      onClick={open}
    >
      <input {...getInputProps()} />
      <CircleIcon size={20} Icon={UploadIcon} />
      <Typography variant="h8" align="center" className="m-t-8px">
        Click to upload or drag and drop
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {helperText}
      </Typography>
    </div>
  );
};

export default FileDropzone;
