import type { JSONContent } from "@tiptap/core";
import {
  type Asset,
  type DallEOutputType,
  getHtmlFromText,
  isHtml,
  type StringAsset
} from "@toolflow/shared";
import useChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useChipsToUse";
import useInputChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";
import { useAssetContext } from "../../../../../../../../utilities/contexts/AssetContext";
import { useAuthStore } from "../../../../../../../auth/hooks/useAuthStore";
import useGetWorkspaceAssetIndexById from "../../../../../assets/useGetWorkspaceAssetIndexById";
import useGetVisibleAssetValue from "../../../../../hooks/useGetVisibleAssetValue";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_ASSETS_FIELD_LABEL } from "../../../../../helpers/workspaceConstants";

export const useUpdateAsset = (asset: Asset) => {
  const getInputIndex = useGetWorkspaceAssetIndexById();

  // we have to use setValue, so that we dirty the form to tigger updates to the cache
  const { setValue } = useFormContext();
  const onUpdate = (
    s: string | JSONContent | DallEOutputType,
    name?: string,
    version?: number,
    loading?: boolean
  ) => {
    const newInput: Asset = {
      ...asset,
      value: s as $TSAllowedAny,
      name: name || asset.name,
      label: name || asset.label,
      data: version ? { version: version + 1 } : { version: 1 },
      loading
    };
    const index = getInputIndex(asset.id);
    setValue(`${WORKSPACE_ASSETS_FIELD_LABEL}.${index}`, newInput, {
      shouldDirty: true,
      shouldTouch: true
    });
  };

  return onUpdate;
};

const useAssetTextField = () => {
  const { isAuthenticated } = useAuthStore();
  const chipsToUse = useInputChipsToUse();
  const chipsWithSnippets = useChipsToUse(chipsToUse);

  const { asset } = useAssetContext<StringAsset>();
  const onUpdate = useUpdateAsset(asset);

  const value = useGetVisibleAssetValue();

  const initialContent = isHtml(value) ? value : getHtmlFromText(value);

  return {
    id: asset.id,
    onUpdate,
    disableEditing: !isAuthenticated,
    initialContent,
    chipsToUse: chipsWithSnippets
  };
};

export default useAssetTextField;
