import { Box, ButtonBase, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";

export function StructuredOutputAccordion({
  name,
  transparentBg,
  children
}: {
  name: string;
  transparentBg?: boolean;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <ButtonBase
        disableRipple
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingBlock: 1,
          paddingInline: 0
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Typography variant="h9" className="capitalize">
          {name}
        </Typography>
        <Box sx={{ rotate: isOpen ? `180deg` : 0 }}>
          <ArrowDownSmallIcon size={16} color="#12172966" />
        </Box>
      </ButtonBase>

      <Box
        sx={{
          display: "grid",
          gridTemplateRows: isOpen ? "1fr" : "0fr",
          transition: "grid-template-rows 300ms ease"
        }}
      >
        <Box sx={{ overflow: "hidden" }}>
          <Box
            sx={{
              borderRadius: 2,
              paddingInline: 1.5,
              paddingBlock: 1.3,
              ...(transparentBg
                ? { border: "1px solid rgba(228, 223, 212, 0.50)" }
                : { backgroundColor: "#FAF8F7" })
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
